// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;
require('../../../node_modules/blueimp-file-upload/css/jquery.fileupload.css');
require('../../../node_modules/blueimp-file-upload/css/jquery.fileupload-ui.css');

require('../../../node_modules/blueimp-file-upload/js/vendor/jquery.ui.widget.js');
require('../../../node_modules/blueimp-file-upload/js/jquery.fileupload.js');
require('../../../node_modules/blueimp-file-upload/js/jquery.fileupload-process.js');
require('../../../node_modules/blueimp-file-upload/js/jquery.fileupload-validate.js');
require('../../../node_modules/blueimp-file-upload/js/jquery.fileupload-ui.js');
require('../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.full.min');
// require('../../../node_modules/jbox/dist/jBox.all.min');

require('../../../node_modules/bootstrap-sass/assets/javascripts/bootstrap.js');
require('../../../node_modules/jquery/dist/jquery.js');
require('../../../node_modules/select2/dist/js/select2.js');
require('../../../node_modules/pdfmake');
require('../assets/fonts/robotoCodensed/vfs_fonts.js');

require('../assets/styles/main/main.scss');
require('../JS/dataTables.responsive.js');
require('../JS/htmltopdfmake/htmltopdfmake.js');

